import {
  getStorage,
  ref,
  connectStorageEmulator,
  uploadBytes,
  getDownloadURL as getUrl,
} from "firebase/storage";
import firebaseApp from "./init";

const storage = getStorage(firebaseApp);

if (process.env.NODE_ENV === 'development') {
  connectStorageEmulator(storage, "localhost", 9199);
}

const getDownloadURL = ((path) => getUrl(path));

const fileExtension = (fileName) => fileName.split('.').pop();

const uploadFile = (file, path) => uploadBytes(ref(storage, path), file);

const getSnapUrl = (snap) => getUrl(snap.ref);

const uploadInvoice = (pdf, xml, expenses, attachments, docId) => {
  const path = `invoices/${docId}`;
  console.log('uploading file at path', path);

  const promises = [
    uploadFile(pdf, `${path}/${pdf.name}`).then(getSnapUrl),
    uploadFile(xml, `${path}/${xml.name}`).then(getSnapUrl),
  ];

  promises.push(
    Promise.all(
      expenses.map((e) => uploadFile(e, `${path}/${e.name}`)),
    ).then((snaps) => {
      console.log('obtained expSnaps');
      return Promise.all(snaps.map(getSnapUrl));
    }).catch((e) => {
      console.log('error with expenses', e);
    }),
  );

  promises.push(
    Promise.all(
      attachments.map((e) => uploadFile(e, `${path}/${e.name}`)),
    ).then((snaps) => {
      console.log('obtained expSnaps');
      return Promise.all(snaps.map(getSnapUrl));
    }).catch((e) => {
      console.log('error with attachments', e);
    }),
  );
  console.log('waiting for promises', promises);
  return Promise.all(promises).catch((e) => {
    console.log('error with promise', e);
  });
};

export default {
  getDownloadURL,
  uploadInvoice,
  fileExtension,
  uploadFile,
};
