<template>
  <v-dialog v-model="dialog" width="500">
    <template v-if="manual != true" v-slot:activator="{ on, attrs }">
      <v-btn class="primary" v-bind="attrs" v-on="on"> Nuevo Grupo </v-btn>
    </template>

    <v-card>
      <v-card-title class="text-h5 primary white--text">
        Nuevo Grupo
      </v-card-title>

      <v-card-text
        ><v-form lazy-validation ref="form">
          <v-text-field
            label="Nombre"
            v-model="name"
            :rules="[rules.required]"
            prepend-icon="mdi-account"
          ></v-text-field>
        </v-form>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="dialog = false"> Cancelar </v-btn>
        <v-btn color="primary" :loading="loading" @click="saveGroup">
          Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import database from "@/firebase/database";

export default {
  props: ["manual"],
  data() {
    return {
      name: null,
      dialog: false,
      loading: false,
      rules: {
        required: (value) => !!value || "Requerido.",
      },
    };
  },
  methods: {
    async saveGroup() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.loading = true;
      await database.writeGroup(null, { name: this.name });
      this.loading = false;
      this.dialog = false;
      this.name = null;
    },
  },
};
</script>

<style>
</style>
