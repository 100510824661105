<template>
  <div ref="dandd">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "DragAndDrop",
  props: ["title", "isUploading"],
  components: {},
  data() {
    return {
      isHovering: false,
      counter: 0,
    };
  },
  computed: {
    cardColor() {
      return this.isHovering ? "deep-purple" : "transparent";
    },
  },

  mounted() {
    const self = this;
    const container = this.$refs.dandd;

    container.addEventListener("dragenter", () => {
      self.isHovering = true;
      self.counter += 1;
    });

    container.addEventListener("dragleave", (e) => {
      e.preventDefault();

      self.counter -= 1;
      if (self.counter === 0) {
        self.isHovering = false;
      }
    });

    container.addEventListener("dragover", (e) => {
      e.preventDefault();
    });

    container.addEventListener("drop", (e) => {
      e.preventDefault();

      this.$emit("files", e.dataTransfer.files);
    });
  },
};
</script>

<style>
#dropzone {
  background-color: #f5f6fa;
  /* border-style: dashed; */
  border-width: 2px;
  /* border-color: #9652ff; */
}
</style>
