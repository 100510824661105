<template>
  <v-dialog v-model="dialog" width="500" persistent>
    <template v-if="manual != true" v-slot:activator="{ on, attrs }">
      <v-btn class="primary" v-bind="attrs" v-on="on"> Nuevo Cliente </v-btn>
    </template>

    <v-card>
      <v-card-title class="text-h5 primary white--text"> Nuevo Cliente </v-card-title>

      <v-card-text
        ><v-form lazy-validation ref="form">
          <v-text-field
            label="Nombre"
            v-model="name"
            :rules="[rules.required]"
            prepend-icon="mdi-account"
          ></v-text-field>
          <v-text-field
            label="RFC"
            v-model="rfc"
            :disabled="clientId != null"
            :rules="[rules.required, rules.rfc]"
            prepend-icon="mdi-fingerprint"
          ></v-text-field>
          <v-text-field
            v-model="email"
            label="Correo"
            prepend-icon="mdi-email"
            :rules="[rules.email]"
          ></v-text-field>
          <v-select
            :items="groups"
            hide-details
            item-text="name"
            item-value="id"
            label="Grupo"
            v-model="group"
            prepend-icon="mdi-account-group"
          >
          </v-select>
          <!-- <div
            class="d-flex flex-row align-center"
            v-for="(email, index) in emails"
            :key="index"
          >
            <v-text-field
              v-model="emails[index]"
              label="Correo"
              prepend-icon="mdi-email"
              :rules="[rules.required, rules.email]"
            ></v-text-field>
            <v-btn
              icon
              class="ml-3"
              v-if="index == emails.length - 1"
              @click="emails.push('')"
            >
              <v-icon>mdi-plus-circle</v-icon>
            </v-btn>
            <v-btn icon class="mt-3" v-else @click="emails.splice(index, 1)">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div> -->
        </v-form>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="closeDialog"> Cancelar </v-btn>
        <v-btn color="primary" :loading="loading" @click="saveUser"> Guardar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import database from "@/firebase/database";

export default {
  props: ["manual"],
  data() {
    return {
      name: null,
      email: null,
      rfc: null,
      dialog: false,
      loading: false,
      group: null,
      clientId: null,
      rules: {
        required: (value) => !!value || "Requerido.",
        rfc: (value) =>
          // eslint-disable-next-line implicit-arrow-linebreak
          (value && (value.length === 12 || value.length === 13)) || "RFC invalido",
        email: (text) =>
          // eslint-disable-next-line implicit-arrow-linebreak
          !text ||
          /[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,64}/.test(text) ||
          "Introduce un correo valido",
      },
    };
  },
  computed: {
    groups() {
      return Object.values(this.$store.state.groups);
    },
  },
  methods: {
    closeDialog() {
      this.dialog = false;
      this.$emit("closed");
    },
    editClient(client) {
      this.clientId = client.id;
      this.name = client.name;
      this.email = client.email;
      this.group = client.group;
      this.rfc = client.rfc;
    },
    async saveUser() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.loading = true;
      const data = {
        name: this.name,
        rfc: this.rfc.toUpperCase(),
      };
      if (this.email) {
        data.email = this.email;
      }
      if (this.group) {
        data.group = this.group;
      }
      await database.writeClient(this.clientId, data);
      this.loading = false;
      this.dialog = false;
      this.name = null;
      this.email = null;
      this.$emit("addedClient");
    },
  },
};
</script>
