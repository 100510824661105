<template>
  <v-card class="pa-2" justify="center" id="dropzone" @click="$refs.image.click()" flat>
    <input
      type="file"
      style="display: none"
      ref="image"
      :multiple="multiple"
      @change="onFilePicked"
    />

    <div class="d-flex align-center justify-center" style="height:100%">
      <h2 class="mt-2">{{ title }}</h2>
      <v-icon large class="mt-2" color="primary">mdi-upload</v-icon>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "DragAndDrop",
  props: ["title", "multiple"],
  components: {},
  data() {
    return {};
  },

  methods: {
    onFilePicked(e) {
      const { files } = e.target;
      this.$emit("submit", files);
    },
  },
};
</script>

<style>
#dropzone {
  background-color: #f5f6fa;
  /* border-style: dashed; */
  border-width: 2px;
  /* border-color: #9652ff; */
}
</style>
