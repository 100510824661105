var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('div',{staticClass:"d-flex flex-row justify-space-between align-end"},[_c('div',{staticClass:"text-h3"},[_vm._v("Facturas")]),_c('v-btn',{staticClass:"primary",on:{"click":_vm.newInvoice}},[_vm._v("Nueva factura")])],1),_c('v-text-field',{staticStyle:{"max-width":"200px"},attrs:{"append-icon":"mdi-magnify","label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-data-table',{staticClass:"elevation-1 mt-2",attrs:{"headers":_vm.headers,"items":_vm.mappedInvoices,"items-per-page":15,"search":_vm.search,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"click:row":_vm.viewClient,"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"item.client",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-row"},[_c('div',{staticStyle:{"max-width":"200px","text-overflow":"ellipsis","overflow":"hidden","white-space":"nowrap"}},[_vm._v(" "+_vm._s(item.client)+" ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(item.message_failures),expression:"item.message_failures"}],attrs:{"color":"warning"}},'v-icon',attrs,false),on),[_vm._v(" mdi-alert ")])]}}],null,true)},[_c('span',[_vm._v("Error enviando a "),_vm._l((item.message_failures),function(errorCode,email){return _c('span',{key:email},[_vm._v(" "+_vm._s(email.replace(',','.'))+",  ")])})],2)])],1)]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toString")(item.date,"Pp"))+" ")]}},{key:"item.paid",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-row align-center"},[_c('div',{staticStyle:{"width":"28px"}},[(item.paid)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-check-circle")]):(item.invoice.track)?_c('v-icon',{attrs:{"color":"warning"}},[_vm._v("mdi-clock")]):_c('v-icon',{attrs:{"color":"warning"}},[_vm._v("mdi-help-circle")])],1),_vm._v(" "+_vm._s(_vm._f("toMoney")(item.amount))+" ")])]}},{key:"item.expenses_paid",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-row align-center"},[_c('div',{staticStyle:{"width":"28px"}},[(item.expenses_paid)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" mdi-check-circle ")]):(item.expenses_paid != null)?_c('v-icon',{attrs:{"color":"warning"}},[_vm._v(" mdi-help-circle ")]):_vm._e()],1),_vm._v(" "+_vm._s(_vm._f("toMoney")(item.total_expenses))+" ")])]}},{key:"item.group",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","icon":"","dark":""}},on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-subheader',{staticClass:"primary--text"},[_vm._v(" Honorarios "),_c('v-btn',{attrs:{"small":"","icon":"","href":item.invoice.pdf_url,"target":"_blank"}},[_c('v-icon',{attrs:{"color":"primary","small":""}},[_vm._v("mdi-open-in-new")])],1)],1),(!item.invoice.track)?_c('v-list-item',{on:{"click":function($event){return _vm.togglePaid(item.id, !item.paid)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(item.paid ? "mdi-close-circle" : "mdi-check-circle"))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(("Honorarios " + (item.paid ? "no" : "") + " pagados")))])],1)],1):_vm._e(),_vm._l((item.expenses),function(expense,index){return [_c('v-subheader',{key:((expense.name) + "_header"),staticClass:"primary--text"},[_vm._v("Gasos "+_vm._s(expense.total)+" "),_c('v-btn',{attrs:{"small":"","icon":"","href":expense.url,"target":"_blank"}},[_c('v-icon',{attrs:{"color":"primary","small":""}},[_vm._v("mdi-open-in-new")])],1)],1),_c('v-list-item',{key:((expense.name) + "_toggle"),on:{"click":function($event){return _vm.toggleExpenses(item.id, index, !expense.paid)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(expense.paid ? "mdi-close-circle" : "mdi-check-circle"))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(("Gastos " + (expense.paid ? "no" : "") + " pagados")))])],1)],1)]}),_c('v-list-item',{on:{"click":function($event){_vm.cancelInvoiceId = item.id;
              _vm.cancelInvoiceDialog = true;}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-delete")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s("Cancelar Factura"))])],1)],1)],2)],1)]}}],null,true)}),_c('NewInvoiceDialog',{ref:"invoiceDialog"}),_c('ClientInvoices',{ref:"clientInvoices"}),_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.cancelInvoiceDialog),callback:function ($$v) {_vm.cancelInvoiceDialog=$$v},expression:"cancelInvoiceDialog"}},[(_vm.invoices && _vm.cancelInvoiceId)?_c('v-card',[_c('v-card-title',{staticClass:"text-h5 primary white--text"},[_vm._v(" Cancelar Factura ")]),_c('v-card-text',{staticClass:"pt-4"},[_vm._v(" Estas seguro que deseas cancelar la factura con folio "+_vm._s(_vm.invoices[_vm.cancelInvoiceId].invoice.folio)+"? ")]),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.cancelInvoiceDialog = false}}},[_vm._v(" No")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.cancelInvoice(_vm.cancelInvoiceId)}}},[_vm._v(" Si, Borrar ")])],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }