import {
  getFirestore,
  collection as docCol,
  doc,
  updateDoc,
  deleteDoc,
  onSnapshot,
  setDoc,
  arrayUnion,
  connectFirestoreEmulator,
} from 'firebase/firestore';
import firebaseApp from "./init";

const firestore = getFirestore(firebaseApp);

if (process.env.NODE_ENV === 'development') {
  connectFirestoreEmulator(firestore, "localhost", 8081);
}

const invoicesCollection = docCol(firestore, 'invoices');

const listenInvoices = (context) => {
  onSnapshot(invoicesCollection, (snapshot) => {
    snapshot.docChanges().forEach((change) => {
      const document = {
        ...change.doc.data(),
        id: change.doc.id,
        collection: invoicesCollection.id,
      };

      if (change.type === "removed") {
        context.commit('deleteCollectionDocument', { collection: invoicesCollection.id, documentId: change.doc.id });
      } else {
        context.commit('updateCollectionDocument', { collection: invoicesCollection.id, document });
      }
    });
  });
};

const saveInvoice = (id, data) => setDoc(doc(invoicesCollection, id), data);
const updateInvoice = (id, data) => updateDoc(doc(invoicesCollection, id), data);
const deleteInvoice = (id, data) => deleteDoc(doc(invoicesCollection, id));

export default {
  newInvoiceId: () => doc(invoicesCollection).id,
  arrayUnion,
  saveInvoice,
  updateInvoice,
  listenInvoices,
  deleteInvoice,
};
