import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store/index';
import Login from '../views/Login';
import Abogados from '../components/abogados/Abogados';
import Clientes from '../components/clientes/Clientes';
import Facturas from '../components/invoices/Invoices';
import Grupos from '../components/groups/Groups.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Facturas,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      isForLogin: true,
    },
  },
  {
    path: '/abogados',
    name: 'Abogados',
    component: Abogados,

  },
  {
    path: '/clientes',
    name: 'Clientes',
    component: Clientes,

  },
  {
    path: '/facturas',
    name: 'Facturas',
    component: Facturas,
  },
  {
    path: '/grupos',
    name: 'Grupos',
    component: Grupos,
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next) => {
  // check to see if route requires auth
  if (to.matched.some((rec) => rec.meta.isForLogin)) {
    console.log('isForLogin');
    const user = store.state.firUser;
    if (user) {
      next({ name: 'Home' });
    } else {
      next();
    }
  } else {
    // check auth state of user
    const user = store.state.firUser;
    if (user) {
      next();
    } else {
      next({ name: 'Login' });
    }
  }
});

export default router;
