import { initializeApp } from "firebase/app";

// eslint-disable-next-line no-unused-vars
const firebaseConfig = {
  apiKey: "AIzaSyDQH3rXYqqbCLEYdAxtnSlmSV_e31fv3TI",
  authDomain: "payments-reminder.firebaseapp.com",
  projectId: "payments-reminder",
  storageBucket: "payments-reminder.appspot.com",
  messagingSenderId: "447096519910",
  databaseURL: "https://payments-reminder-default-rtdb.firebaseio.com",
  appId: "1:447096519910:web:be42ee2c96ceb37a8f8f27",
};

// Initialize Firebase
export default initializeApp(firebaseConfig);
