<template>
  <v-app>
    <NavDrawer />
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import "@/firebase/init";
import NavDrawer from "@/components/NavDrawer";
import login from "@/firebase/login";

export default {
  name: "App",
  components: { NavDrawer },
  computed: {
    signedIn() {
      return this.$store.state.firUser != null;
    },
  },
  methods: {
    navClick() {
      console.log("nav Click");
    },
    logout() {
      this.$store.dispatch("logOut", this.$store.state.firUser);
      login.signOut().then(() => {
        this.$router.push({ name: "Login" });
      });
    },
  },
  created() {
    console.log("app creatted");
  },
};
</script>
