<template>
  <v-container>
    <div class="d-flex flex-row justify-space-between">
      <h2>Abogados</h2>
      <AddAbogadoDialog ref="sellerDialog" />
    </div>
    <v-card class="my-4 mx-4">
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="abogados"
          item-key="id"
          :search="search"
          @click:row="handleClick"
        >
          <template v-slot:top>
            <v-text-field v-model="search" label="Buscar" class="mx-4">
            </v-text-field>
          </template>

          <template v-slot:[`item.id`]="{ item }">
            <v-menu offset-y>
              <template v-slot:activator="{ on }">
                <v-btn color="primary" icon dark v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="editSeller(item)">
                  <v-list-item-icon>
                    <v-icon>mdi-pencil</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Editar</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <AbogadoInvoices ref="abogadoInvoices" />
  </v-container>
</template>

<script>
import AddAbogadoDialog from "./AddAbogadoDialog.vue";
import AbogadoInvoices from "./AbogadoInvoices";

export default {
  components: {
    AddAbogadoDialog,
    AbogadoInvoices,
  },
  data() {
    return {
      search: "",
      headers: [
        { text: "Nombre", value: "name" },
        { text: "Correos", value: "email" },
        { text: "Acciones", value: "id" },
      ],
    };
  },
  methods: {
    handleClick(item) {
      this.$refs.abogadoInvoices.dialog = true;
      this.$refs.abogadoInvoices.abogadoId = item.id;
    },
    editSeller(seller) {
      this.$refs.sellerDialog.dialog = true;
      this.$refs.sellerDialog.editSeller(seller);
    },
  },
  computed: {
    abogados() {
      return Object.values(this.$store.state.abogados);
    },
  },
};
</script>
