import {
  getDatabase,
  ref,
  onChildAdded,
  onChildChanged,
  update,
  child,
  off,
  push,
  set,
  connectDatabaseEmulator,
} from "firebase/database";
import firebaseApp from "./init";

const database = getDatabase(firebaseApp);

if (process.env.NODE_ENV === 'development') {
  connectDatabaseEmulator(database, "localhost", 9000);
}

const sellersRef = ref(database, 'sellers/');
const clientsRef = ref(database, 'customers/');
const groupsRef = ref(database, 'groups/');

const observeRef = (context, observingRef, collection) => {
  const saveDoc = (snapshot) => {
    const doc = { ...snapshot.val(), id: snapshot.key };
    context.commit('updateCollectionDocument', { collection, document: doc });
  };
  onChildAdded(observingRef, saveDoc);
  onChildChanged(observingRef, saveDoc);
};

const startObservers = (context) => {
  observeRef(context, sellersRef, 'abogados');
  observeRef(context, clientsRef, 'clientes');
  observeRef(context, groupsRef, 'groups');
};

const writeSeller = (id, seller) => (id ?
  update(child(sellersRef, id), seller) : push(sellersRef, seller));

const writeClient = (id, client) => (id ?
  set(child(clientsRef, id), client) : push(clientsRef, client));

const writeGroup = (id, group) => (id ?
  update(child(groupsRef, id), group) : push(groupsRef, group));

const stopObservers = () => {
  off(sellersRef);
  off(clientsRef);
  off(groupsRef);
};

export default {
  startObservers,
  stopObservers,
  writeSeller,
  writeClient,
  writeGroup,
};
