<template>
  <v-dialog v-model="dialog" width="700">
    <v-card v-if="client">
      <div class="text-h6 d-flex d-flex-row justify-space-between">
        <div>
          <v-card-title> {{ client.name }}</v-card-title>
          <v-card-subtitle class="pt-1 d-flex d-row justify-space-between">
            <div>RFC:{{ client.rfc }}</div>
            <div>Correo:{{ client.email }}</div>
          </v-card-subtitle>
        </div>
        <v-card class="mt-1">
          <v-card-title>Sin Pagar</v-card-title>
          <v-card-subtitle class="text-center">
            {{ totalOwed | toMoney }}
          </v-card-subtitle>
        </v-card>
        <v-btn class="primary mt-4 mr-4" color="white" icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>

      <v-card-text>
        <v-data-table :headers="headers" :items="mappedInvoices" :items-per-page="15" class="mt-2">
          <template v-slot:[`item.date`]="{ item }">
            {{ item.date | toString("Pp") }}
          </template>
          <template v-slot:[`item.paid`]="{ item }">
            <v-icon v-if="item.paid" color="success">mdi-check-circle</v-icon>
          </template>
          <template v-slot:[`item.group`]="{ item }">
            <v-menu offset-y>
              <template v-slot:activator="{ on }">
                <v-btn color="primary" icon dark v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item v-if="!item.paid" @click="markPaid(item.id)">
                  <v-list-item-icon>
                    <v-icon>mdi-check-circle</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Marcar como pagada</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      dialog: false,
      clientId: null,
      headers: [
        { text: "No", value: "internal_id" },
        { text: "Abogado", value: "abogado" },
        { text: "Fecha", value: "date" },
        { text: "Cantidad", value: "amount" },
        { text: "Pagada", value: "paid" },
        { text: "Acciones", value: "group" },
      ],
    };
  },
  computed: {
    ...mapState(["abogados", "clientes", "invoices"]),
    client() {
      if (!this.clientId) return null;
      return this.$store.state.clientes[this.clientId];
    },
    clientInvoices() {
      if (!this.abogados || !this.clientes || !this.invoices) return [];
      return Object.values(this.invoices).filter(
        (invoice) => invoice.customer_id === this.clientId,
      );
    },
    totalOwed() {
      return this.clientInvoices.reduce(
        (sum, charge) => sum + (charge.invoice.paid ? 0 : charge.invoice.total),
        0,
      );
    },
    mappedInvoices() {
      if (!this.clientInvoices) return [];
      return this.clientInvoices.map((invoice) => ({
        id: invoice.id,
        abogado: (this.abogados[invoice.abogado] || {}).name,
        date: invoice.invoice.date,
        internal_id: invoice.internal_id,
        paid: invoice.invoice.paid,
        amount: this.$options.filters.toMoney(invoice.invoice.total),
        group: (this.clientes[invoice.customer_id] || {}).group,
      }));
    },
  },
};
</script>
