<template>
  <v-container>
    <div class="d-flex flex-row justify-space-between">
      <h2>Grupos</h2>
      <AddGroupDialog />
    </div>
    <v-card class="my-4 mx-4">
      <v-card-text>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Nombre</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in abogados" :key="item.id">
                <td>{{ item.name }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import AddGroupDialog from "./AddGroupDialog";

export default {
  components: {
    AddGroupDialog,
  },
  data() {
    return {};
  },
  computed: {
    abogados() {
      return this.$store.state.groups;
    },
  },
};
</script>
