<template>
  <v-container>
    <v-img :src="require('@/assets/logo-notaria.png')" width="544" class="mx-auto"></v-img>
    <v-card max-width="544" class="mt-4 mx-auto px-4">
      <v-card-title>Iniciar Sesión</v-card-title>
      <v-card-text>
        <v-form lazy-validation ref="form">
          <v-text-field
            label="Email"
            v-model="email"
            prepend-icon="mdi-email"
            :rules="[rules.required, rules.email]"
          />
          <v-text-field
            label="Contraseña"
            v-model="password"
            prepend-icon="mdi-lock-question"
            :rules="[rules.required, rules.min]"
            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show ? 'text' : 'password'"
            name="input-10-1"
            hint="Al menos 6 caracteres"
            counter
            @click:append="show = !show"
          />
          <p class="red--text" v-if="feedback">{{ feedback }}</p>
          <v-spacer></v-spacer>

          <div class="mt-4 text-right">
            <v-btn color="primary" dark @click="emailSignIn()" :loading="loading"
              >Iniciar Sesión</v-btn
            >
          </div>
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import login from "@/firebase/login";

export default {
  name: "Login",

  data() {
    return {
      email: "",
      password: "",
      show: false,
      rules: {
        required: (value) => !!value || "Campo requerido",
        email: (text) => /[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,64}/.test(text) ||
          "Ingresa un correo valido",
        min: (v) => v.length >= 6 || "Minimo 6 caracteres",
      },
      loading: false,
      feedback: null,
    };
  },
  methods: {
    emailSignIn() {
      if (!this.$refs.form.validate()) {
        console.log("errors exist");
        return;
      }
      this.loading = true;
      this.feedback = null;
      login
        .emailSignIn(this.email, this.password)
        .then(() => {
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message;
          this.feedback = error.message;
          console.log("myError", errorCode, errorMessage);
        });
    },
  },
};
</script>
