import Vue from 'vue';
import { format, parseISO } from "date-fns";
import { es } from "date-fns/locale";

const locale = es;

Vue.filter('toString', (value, strFormat) => {
  if (!value) return '';
  let date = value;
  if (!(date instanceof Date)) { date = value.toDate(); }
  return format(date, strFormat, { locale });
});

Vue.filter('strToDate', (value, strFormat) => {
  if (!value) return '';
  return format(parseISO(value), strFormat, { locale });
});

const formatter = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
});
Vue.filter('toMoney', (value) => {
  if (!value) return '-';
  return formatter.format(value);
});
