import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import login from './firebase/login';
import './filters';

Vue.config.productionTip = false;

let app;
let isSignedIn = false;
// wait for firebase auth to init before creating the app
login.waitForLogin((user) => {
  console.log('waitForLogin');
  // init app if not already created
  store.commit('firUserUpdated', user);
  if (user) {
    isSignedIn = true;
    console.log('waitForLogin->Logged in user', user.uid, user.email);
    // store.dispatch('userLogedIn', user)
    store.dispatch("getInitialData");
    if (router.currentRoute.meta && router.currentRoute.meta.isForLogin) {
      if (router.startUrl) {
        console.log(`pushing to ${router.startUrl}`);
        router.push({ path: router.startUrl });
      } else {
        router.push({ name: "Home" });
      }
    }
  } else if (isSignedIn) {
    console.log("signing out");
    isSignedIn = false;
    router.push({ name: "Login" });
    store.dispatch("logOut", store.state.firUser);
  }

  if (!app) {
    /* eslint-disable no-new */
    app = new Vue({
      router,
      store,
      vuetify,
      render: (h) => h(App),
    }).$mount('#app');
  }
});
