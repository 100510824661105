var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"700"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[(_vm.client)?_c('v-card',[_c('div',{staticClass:"text-h6 d-flex d-flex-row justify-space-between"},[_c('div',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.client.name))]),_c('v-card-subtitle',{staticClass:"pt-1 d-flex d-row justify-space-between"},[_c('div',[_vm._v("RFC:"+_vm._s(_vm.client.rfc))]),_c('div',[_vm._v("Correo:"+_vm._s(_vm.client.email))])])],1),_c('v-card',{staticClass:"mt-1"},[_c('v-card-title',[_vm._v("Sin Pagar")]),_c('v-card-subtitle',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm._f("toMoney")(_vm.totalOwed))+" ")])],1),_c('v-btn',{staticClass:"primary mt-4 mr-4",attrs:{"color":"white","icon":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[_c('v-data-table',{staticClass:"mt-2",attrs:{"headers":_vm.headers,"items":_vm.mappedInvoices,"items-per-page":15},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toString")(item.date,"Pp"))+" ")]}},{key:"item.paid",fn:function(ref){
var item = ref.item;
return [(item.paid)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-check-circle")]):_vm._e()]}},{key:"item.group",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","icon":"","dark":""}},on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[(!item.paid)?_c('v-list-item',{on:{"click":function($event){return _vm.markPaid(item.id)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-check-circle")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Marcar como pagada")])],1)],1):_vm._e()],1)],1)]}}],null,true)})],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }