<template>
  <v-dialog v-model="dialog" width="900" persistent>
    <v-card class="overflow-y-auto">
      <v-card-title class="primary d-flex flex-row justify-space-between">
        <div class="white--text">
          <span class="text-h5"> Nueva Factura</span>
          <span class="text-h6" v-if="clientId">
            <br />
            <b>{{ client.name }}</b>
          </span>
        </div>
        <v-btn color="white" icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <div v-if="!saveSuccess">
        <v-card-title class="pb-0"> Notaría </v-card-title>
        <v-card-text>
          <div class="d-flex flex-row">
            <v-combobox
              v-model="internalIdType"
              :items="idTypes"
              placeholder="Tipo"
              style="max-width: 200px"
            ></v-combobox>
            <v-text-field
              hide-details
              class="mx-4"
              style="max-width: 150px"
              :label="`Numero ${internalIdType}`"
              v-model="internalId"
            ></v-text-field>
            <v-select
              class="ml-4"
              :items="abogados"
              hide-details
              item-text="name"
              style="max-width: 150px"
              item-value="id"
              label="Abogado"
              v-model="abogado"
            ></v-select>
            <v-checkbox
              hide-details
              class="ml-4"
              v-model="sendLawyerEmail"
              label="Enviar correo"
            ></v-checkbox>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-title class="py-1"> Factura </v-card-title>
        <v-card-text class="mt-2">
          <v-row>
            <v-col cols="5">
              <DragAndDrop @files="filesDroped">
                <v-row>
                  <v-col v-for="(file, key) in files" :key="key" cols="12" md="6">
                    <v-card>
                      <v-card-text class="text-center pa-0">
                        <div v-if="files[key].file">
                          <div class="d-flex flex-row justify-center align-center">
                            <h2 class="mt-2">{{ file.name }}</h2>
                            <v-icon class="pl-2 pt-2" color="green">mdi-check-circle</v-icon>
                          </div>
                          <v-icon color="primary" class="mt-2" large>
                            {{ key == "pdf" ? "mdi-file-pdf-box" : "mdi-file-document" }}</v-icon
                          >
                          <p class="ma-0 pt-2">{{ files[key].file.name }}</p>
                        </div>
                        <SelectFile
                          v-else
                          :title="file.name"
                          :multiple="true"
                          @submit="filesDroped"
                          style="height: 110px"
                        />
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </DragAndDrop>
              <div class="d-flex justify-center">
                <v-btn
                  v-if="files['pdf'].file || files['xml'].file"
                  class="primary mt-2"
                  @click="removeFiles"
                >
                  Quitar archivos
                </v-btn>
              </div>
            </v-col>

            <v-col cols="7" v-if="factura">
              <v-card>
                <v-card-text>
                  <div class="d-flex flex-row justify-space-between align-start">
                    <div class="d-flex flex-column">
                      <div class="font-weight-bold">RFC: {{ factura.rfc }}</div>
                      <div v-if="client.email" class="d-flex flex-row align-center mb-1">
                        Correo: {{ client.email }}
                        <v-checkbox
                          hide-details
                          dense
                          class="ml-1 ma-0"
                          v-model="sendClientEmail"
                        ></v-checkbox>
                      </div>
                      <div
                        v-for="(email, index) in emails"
                        class="d-flex flex-row align-center"
                        :key="index"
                      >
                        <v-text-field
                          class="py-1"
                          placeholder="Correo"
                          @paste="onPaste(index)"
                          hide-details
                          v-model="emails[index]"
                        >
                        </v-text-field>
                        <v-btn
                          icon
                          dark
                          x-small
                          class="primary mr-2"
                          @click="emails.splice(index, 1)"
                        >
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </div>
                      <span
                        class="text-caption error--text"
                        v-if="!sendClientEmail && emails.length === 0"
                      >
                        No se enviará ningún correo
                      </span>
                      <v-btn x-small class="primary mt-2" @click="addEmail">agregar correo</v-btn>
                    </div>
                    <div class="d-flex flex-column align-end">
                      <div class="font-weight-light">
                        Fecha: {{ factura.date | toString("Pp") }}
                      </div>
                      <div>Folio: {{ factura.folio }}</div>
                      <div class="text-h5">Total: {{ factura.total }}</div>
                    </div>
                  </div>

                  <!-- <v-select
                  :items="client.emails"
                  label="Correo"
                  multiple
                  v-model="emails"
                ></v-select> -->
                  <div class="d-flex flex-row mt-2">
                    <v-checkbox
                      class="ma-0"
                      label="Pagado"
                      persistent-hint
                      hint="No se envian datos de pago"
                      v-model="paid"
                    ></v-checkbox>
                    <v-checkbox
                      class="ma-0 ml-2"
                      label="Rastrear pago"
                      persistent-hint
                      v-show="!paid"
                      hint="Al apagarse se usa la cuenta de Banorte"
                      v-model="trackPayment"
                    ></v-checkbox>
                    <v-checkbox
                      class="ma-0 ml-2"
                      label="Recordatorios"
                      persistent-hint
                      v-show="!paid && !trackPayment"
                      hint="Enviar recordatorios semanales"
                      v-model="sendReminders"
                    ></v-checkbox>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>

        <v-row>
          <v-col cols="12" lg="6">
            <v-checkbox class="ma-0 ml-4" hide-details v-model="hasExpenses">
              <template v-slot:label>
                <div class="text-h6">Gastos Notariales</div>
              </template>
            </v-checkbox>

            <div style="min-height: 170px">
              <v-slide-y-reverse-transition>
                <div v-show="hasExpenses">
                  <div class="d-flex flex-column">
                    <v-card
                      class="ml-4 mt-2"
                      width="350px"
                      v-for="(expense, index) in expenses"
                      :key="index"
                    >
                      <v-btn
                        style="position: absolute; right: 0; top: 0"
                        icon
                        @click="removeExpense(index)"
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                      <v-card-text>
                        <div class="d-flex flex-row mt-2">
                          <DragAndDrop @files="gastosDroped(index, $event)">
                            <v-card width="140px">
                              <v-card-text class="text-center pa-0">
                                <div v-if="expense.pdf.file">
                                  <div class="d-flex flex-row justify-center align-center">
                                    <h2 class="mt-2">{{ expense.pdf.name }}</h2>
                                    <v-icon class="pl-2 pt-2" color="green"
                                      >mdi-check-circle</v-icon
                                    >
                                  </div>
                                  <v-icon color="primary" class="mt-2" large>
                                    mdi-file-pdf-box
                                  </v-icon>
                                  <p class="ma-0 pt-2">{{ expense.pdf.file.name }}</p>
                                </div>
                                <SelectFile
                                  v-else
                                  :title="expense.pdf.name"
                                  @submit="gastosDroped(index, $event)"
                                  style="height: 110px"
                                />
                              </v-card-text>
                            </v-card>
                          </DragAndDrop>
                          <div class="ml-4">
                            <v-text-field
                              style="max-width: 150px"
                              label="Total Gastos"
                              prefix="$"
                              v-model="expense.total"
                              hide-details
                            >
                            </v-text-field>
                            <v-checkbox
                              label="Pagado"
                              persistent-hint
                              hint="No se envian datos de pago"
                              v-model="expense.paid"
                            ></v-checkbox>
                          </div>
                        </div>
                        <div v-if="expense.feedback" class="mt-2 text-end error--text">
                          {{ expense.feedback }}
                        </div>
                      </v-card-text>
                    </v-card>
                  </div>
                  <v-btn class="primary mx-5 mt-2" @click="moreExpenses">
                    <v-icon left>mdi-plus</v-icon>Más gastos
                  </v-btn>
                </div>
              </v-slide-y-reverse-transition>
            </div>
          </v-col>
          <v-col cols="12" lg="6">
            <div class="text-h6 ml-4 grey--text text--darken-1">Archivos Adjuntos</div>
            <v-card class="mt-2 ml-4" width="350px">
              <v-card-text>
                <div class="d-flex flex-row">
                  <DragAndDrop @files="attachmentsDroped">
                    <v-card width="140px">
                      <v-card-text class="text-center pa-0">
                        <SelectFile
                          title="Adjuntos"
                          @submit="attachmentsDroped"
                          style="height: 110px"
                        />
                      </v-card-text>
                    </v-card>
                  </DragAndDrop>
                  <div class="ml-2" style="width: 100%">
                    <div v-for="(attachment, index) in attachments" :key="index">
                      <div class="d-flex flex-row justify-space-between">
                        <span
                          style="
                            max-width: 140px;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                          "
                          >{{ attachment.name }}</span
                        >

                        <v-btn icon x-small @click="attachments.splice(index, 1)">
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </div>
                    </div>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-card-text class="mt-4" v-if="dialog">
          <div v-if="feedback" class="mt-2 text-end error--text">
            {{ feedback }}
          </div>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :loading="loading"
            :disabled="factura == undefined"
            color="primary"
            @click="save()"
          >
            Guardar
          </v-btn>
        </v-card-actions>
      </div>
      <!-- <v-template > -->
      <v-expand-transition>
        <v-card-text v-show="saveSuccess" class="text-center">
          <div class="d-flex flex-row justify-center">
            <div>
              <v-icon size="100" color="primary">mdi-check-circle-outline</v-icon>

              <v-card-title class="justify-center">Factura guardada</v-card-title>
              <v-card-subtitle class="justify-center">
                Tu factura fue guardada exitosamente
              </v-card-subtitle>
            </div>
          </div>
        </v-card-text>
      </v-expand-transition>
      <v-card-actions v-if="saveSuccess">
        <v-btn color="primary" outlined @click="resetForm"> Crear otra </v-btn>
        <v-btn color="primary" @click="close">Cerrar</v-btn>
      </v-card-actions>
    </v-card>

    <AddClientDialog
      ref="AddClientRef"
      :manual="true"
      @addedClient="clientAdded"
      @closed="removeFiles"
    />
  </v-dialog>
</template>

<script>
import convert from "xml-js";
import firestore from "@/firebase/firestore";
import storage from "@/firebase/storage";
import SelectFile from "./SelectFile";
import DragAndDrop from "../clientes/DragAndDrop";
import AddClientDialog from "../clientes/AddClientDialog";

const initialState = () => ({
  dialog: false,
  clientId: null,
  abogado: null,
  sendLawyerEmail: true,
  sendClientEmail: true,
  loading: false,
  hasExpenses: true,
  paid: false,
  expenses: [
    {
      total: null,
      paid: false,
      feedback: null,
      pdf: { name: "PDF", file: null },
    },
  ],
  trackPayment: false,
  sendReminders: true,
  files: {
    pdf: { name: "PDF", file: null },
    xml: { name: "XML", file: null },
  },
  factura: null,
  emails: [],
  attachments: [],
  internalIdType: "Escritura",
  internalId: "",
  feedback: null,
  saveSuccess: false,
});

export default {
  components: {
    SelectFile,
    DragAndDrop,
    AddClientDialog,
  },
  data() {
    return initialState();
  },
  computed: {
    idTypes() {
      return this.$store.state.idTypes ?? [];
    },
    client() {
      if (!this.clientId) return null;
      return this.$store.state.clientes[this.clientId];
    },
    abogados() {
      return Object.values(this.$store.state.abogados);
    },
    myId() {
      return this.$store.state.firUser.uid;
    },
  },
  methods: {
    removeExpense(index) {
      this.expenses.splice(index, 1);
    },
    moreExpenses() {
      this.expenses.push({
        total: null,
        paid: false,
        feedback: null,
        pdf: { name: "PDF", file: null },
      });
    },
    onPaste(index) {
      setTimeout(() => {
        const currentEmails = this.emails[index];
        console.log(currentEmails);

        const splitByCharacter = (emails, char) => {
          if (emails.includes(char)) {
            const e = emails.includes("<") ? emails : emails.replace(/ /gi, "");
            const emailArr = e.split(char);
            this.emails[index] = emailArr.shift();

            emailArr.forEach((email) => {
              if (email !== "") {
                this.emails.push(email);
              }
            });
          }
        };
        splitByCharacter(currentEmails, ",");
        splitByCharacter(currentEmails, ";");
      }, 50);
    },
    addEmail() {
      this.emails.push("");
    },
    close() {
      Object.assign(this.$data, initialState());
    },
    resetForm() {
      Object.assign(this.$data, initialState());
      this.dialog = true;
    },
    removeFiles() {
      this.clientId = null;
      this.factura = null;
      this.files.pdf.file = null;
      this.files.xml.file = null;
    },
    async save() {
      if (this.abogado == null) {
        this.feedback = "Selecciona un abogado";
        return;
      }
      // if (this.emails.length === 0) {
      //   this.feedback = "Selecciona al menos un correo";
      //   return;
      // }
      // if (!this.internalId) {
      //   this.feedback = `Ingresa el numero de ${this.internalIdType}`;
      //   return;
      // }
      if (!this.files.xml.file) {
        this.feedback = "Ingresa el archivo xml";
        return;
      }
      if (!this.files.pdf.file) {
        this.feedback = "Ingresa el archivo pdf";
        return;
      }

      if (this.hasExpenses) {
        // eslint-disable-next-line no-restricted-syntax
        for (const expense of this.expenses) {
          if (!expense.pdf.file) {
            expense.feedback = "Adjunta PDF de gastos";
            return;
          }
          const total = parseFloat(expense.total, 10);
          if (Number.isNaN(total)) {
            expense.feedback = "Ingresa una cantidad valida";
            return;
          }
          expense.feedback = null;
        }
      }

      this.feedback = null;
      this.loading = true;

      const id = firestore.newInvoiceId();
      console.log("uploding");
      console.log(this.expenses.map((e) => e.pdf.file).filter((e) => e));
      const [pdfUrl, xmlUrl, expensesUrls, attachmentsUrls] = await storage.uploadInvoice(
        this.files.pdf.file,
        this.files.xml.file,
        this.expenses.map((e) => e.pdf.file).filter((e) => e),
        this.attachments.map((a) => a),
        id,
      );
      console.log("finished file upload");
      const emails = [...this.emails.filter((e) => e !== "")];
      if (this.sendClientEmail) {
        emails.unshift(this.client.email);
      }

      const data = {
        created_by: this.myId,
        internal_id: this.internalId,
        type: this.internalIdType,
        abogado: this.abogado,
        customer_id: this.clientId,
        emails,
        reminders: this.sendReminders,
        modified: new Date(),
        invoice: {
          ...this.factura,
          track: this.paid ? false : this.trackPayment,
          pdf_url: pdfUrl,
          xml_url: xmlUrl,
          paid: this.paid,
        },
      };
      if (this.sendLawyerEmail) {
        data.seller_email = this.$store.state.abogados[this.abogado].email;
      }
      if (this.hasExpenses) {
        data.expenses = this.expenses.map((e, i) => ({
          url: expensesUrls[i],
          total: parseFloat(e.total, 10),
          paid: e.paid,
          name: e.pdf.file.name,
        }));
      }
      if (this.attachments) {
        data.attachments = this.attachments.map((a, i) => ({
          url: attachmentsUrls[i],
          name: a.name,
        }));
      }
      console.log("saving", data);
      await firestore.saveInvoice(id, data);
      this.loading = false;
      this.saveSuccess = true;
    },
    clientAdded() {
      this.parseXml(this.files.xml.file);
    },
    parseXml(file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const json = convert.xml2json(event.target.result, {
          compact: true,
          spaces: 2,
          attributesKey: "attributes",
        });

        const comprobante = JSON.parse(json)["cfdi:Comprobante"];
        const cAttrs = comprobante.attributes;
        const receptor = comprobante["cfdi:Receptor"].attributes;
        if (this.clientId) {
          if (this.client.rfc !== receptor.Rfc) {
            this.feedback = "El RFC de la factura no coincide con el del cliente";
            return;
          }
        } else {
          const client = Object.values(this.$store.state.clientes).find(
            // eslint-disable-next-line comma-dangle
            (c) => c.rfc === receptor.Rfc,
          );
          if (!client) {
            const dialogRef = this.$refs.AddClientRef;
            dialogRef.name = receptor.Nombre;
            dialogRef.rfc = receptor.Rfc;
            dialogRef.dialog = true;
            return;
          }
          this.clientId = client.id;
          if (!client.email) {
            this.sendClientEmail = false;
          }
        }

        this.factura = {
          folio: cAttrs.Folio || "Sin Folio",
          total: parseFloat(cAttrs.Total),
          date: new Date(cAttrs.Fecha),
          name: receptor.Nombre,
          rfc: receptor.Rfc,
        };
      };
      reader.readAsText(file);
    },
    attachmentsDroped(files) {
      // eslint-disable-next-line no-restricted-syntax
      for (const file of files) {
        this.attachments.push(file);
      }
    },
    filesDroped(files) {
      // eslint-disable-next-line no-restricted-syntax
      for (const file of files) {
        if (file.name.includes(".xml")) {
          this.files.xml.file = file;
          this.parseXml(file);
        } else if (file.name.includes(".pdf")) {
          this.files.pdf.file = file;
        }
      }
    },
    gastosDroped(index, files) {
      const [file] = files;
      if (file.name.includes(".pdf")) {
        this.expenses[index].pdf.file = file;
      }
    },
  },
};
</script>
