<template>
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-btn class="primary" v-bind="attrs" v-on="on"> Nuevo Abogado </v-btn>
    </template>

    <v-card>
      <v-card-title class="text-h5 primary white--text">
        Nuevo Abogado
      </v-card-title>

      <v-card-text
        ><v-form lazy-validation ref="form">
          <v-text-field
            label="Nombre"
            v-model="name"
            :rules="[rules.required]"
          ></v-text-field>
          <v-text-field
            label="Correo"
            v-model="email"
            :rules="[rules.required, rules.email]"
          ></v-text-field>
        </v-form>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="dialog = false"> Cancelar </v-btn>
        <v-btn color="primary" :loading="loading" @click="saveUser">
          Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import database from "@/firebase/database";

export default {
  data() {
    return {
      name: null,
      email: null,
      dialog: false,
      loading: false,
      sellerId: null,
      rules: {
        required: (value) => !!value || "Requerido.",
        email: (text) =>
          // eslint-disable-next-line implicit-arrow-linebreak
          /[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,64}/.test(text) ||
          "Introduce un correo valido",
      },
    };
  },
  methods: {
    editSeller(seller) {
      this.sellerId = seller.id;
      this.name = seller.name;
      this.email = seller.email;
    },
    async saveUser() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.loading = true;
      await database.writeSeller(this.sellerId, { name: this.name, email: this.email });
      this.loading = false;
      this.dialog = false;
      this.name = null;
      this.email = null;
    },
  },
};
</script>

<style>
</style>
