<template>
  <v-container class="px-5">
    <div class="d-flex flex-row justify-space-between">
      <h2>Clientes</h2>
      <AddClientDialog ref="clientDialog" />
    </div>
    <v-card class="my-4">
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="clientes"
          item-key="id"
          :search="search"
          @click:row="handleClick"
        >
          <template v-slot:top>
            <v-text-field
              v-model="search"
              label="Buscar"
              class="mx-4"
            ></v-text-field>
          </template>
          <!-- <template v-slot:[`item.emails`]="{ item }">
            <span
              >{{
                item.emails.length > 1
                  ? `${item.emails[0]} +${item.emails.length - 1}`
                  : item.emails[0]
              }}
            </span>
          </template> -->
          <template v-slot:[`item.id`]="{ item }">
            <v-menu offset-y>
              <template v-slot:activator="{ on }">
                <v-btn color="primary" icon dark v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="newInvoice(item.id)">
                  <v-list-item-icon>
                    <v-icon>mdi-account-cash</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Nueva factura</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item @click="editClient(item)">
                  <v-list-item-icon>
                    <v-icon>mdi-pencil</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Editar</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <NewInvoiceDialog ref="invoiceDialog" />
    <ClientInvoices ref="clientInvoices"/>
  </v-container>
</template>

<script>
import AddClientDialog from "./AddClientDialog";
import NewInvoiceDialog from "../invoices/NewInvoiceDialog";
import ClientInvoices from "../invoices/ClientInvoices";

export default {
  components: {
    AddClientDialog,
    NewInvoiceDialog,
    ClientInvoices,
  },
  data() {
    return {
      search: "",
      headers: [
        { text: "Nombre", value: "name" },
        { text: "RFC", value: "rfc" },
        { text: "Correos", value: "email" },
        { text: "Acciones", value: "id" },
      ],
    };
  },
  methods: {
    handleClick(item) {
      this.$refs.clientInvoices.dialog = true;
      this.$refs.clientInvoices.clientId = item.id;
    },
    newInvoice(clientId) {
      this.$refs.invoiceDialog.clientId = clientId;
      this.$refs.invoiceDialog.dialog = true;
    },
    editClient(client) {
      this.$refs.clientDialog.dialog = true;
      this.$refs.clientDialog.editClient(client);
    },
  },
  computed: {
    clientes() {
      return Object.values(this.$store.state.clientes);
    },
  },
};
</script>
