<template>
  <v-navigation-drawer v-if="signedIn" v-model="drawer" app clipped>
    <v-list-item class="primary" dark>
      <v-list-item-content>
        <v-list-item-title class="text-h6"> Notaria 24 </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-divider></v-divider>
    <v-list class="py-0">
      <v-list-item v-for="item in items" :key="item.title" link :to="item.link">
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <!-- <v-divider></v-divider> -->
    </v-list>
    <template v-slot:append>
      <div class="pa-2">
        <v-btn text v-if="signedIn" color="primary " @click="logout">
          Cerrar Sesión
          <v-icon right>mdi-logout</v-icon>
        </v-btn>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import login from "@/firebase/login";

export default {
  data: () => ({
    drawer: null,
    items: [
      { title: "Facturas", icon: "mdi-file-pdf-box", link: "/facturas" },
      { title: "Clientes", icon: "mdi-account-cash", link: "/clientes" },
      {
        title: "Abogados",
        icon: "mdi-account-group",
        link: "/abogados",
      },
      {
        title: "Grupos",
        icon: "mdi-account-box-multiple",
        link: "/grupos",
      },
    ],
  }),
  computed: {
    signedIn() {
      return this.$store.state.firUser != null;
    },
  },
  methods: {
    logout() {
      login.logout();
    },
  },
};
</script>
