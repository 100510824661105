/* eslint-disable no-param-reassign */
import Vue from 'vue';
import Vuex from 'vuex';
import database from '../firebase/database';
import firestore from '../firebase/firestore';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    firUser: null,
    abogados: {},
    clientes: {},
    invoices: {},
    groups: {},
    idTypes: ['Escritura', 'Acta'],
  },
  mutations: {
    firUserUpdated: (state, user) => { state.firUser = user; },
    logOut: (state) => { state.firUser = null; },
    updateCollectionDocument: (state, payload) => {
      Vue.set(state[payload.collection], payload.document.id, payload.document);
      if (payload.collection === 'invoices' &&
        payload.document.type &&
        !state.idTypes.includes(payload.document.type)) {
        state.idTypes.push(payload.document.type);
      }
    },
    deleteCollectionDocument: (state, payload) => {
      Vue.delete(state[payload.collection], payload.documentId);
    },
    updateValue: (state, payload) => {
      state[payload.collection] = payload.value;
    },
  },
  actions: {
    logOut: (context) => {
      context.commit('logOut');
      database.stopObservers();
    },
    getInitialData: (context) => {
      database.startObservers(context);
      firestore.listenInvoices(context);
    },
  },
  modules: {
  },
});
