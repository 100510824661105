<template>
  <v-container>
    <div class="d-flex flex-row justify-space-between align-end">
      <div class="text-h3">Facturas</div>
      <v-btn class="primary" @click="newInvoice">Nueva factura</v-btn>
    </div>
    <v-text-field
      style="max-width: 200px"
      v-model="search"
      append-icon="mdi-magnify"
      label="Buscar"
      single-line
      hide-details
    ></v-text-field>
    <v-data-table
      :headers="headers"
      :items="mappedInvoices"
      :items-per-page="15"
      :search="search"
      class="elevation-1 mt-2"
      @click:row="viewClient"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
    >
      <template v-slot:[`item.client`]="{ item }">
        <div class="d-flex flex-row">
          <div
            style="max-width: 200px; text-overflow: ellipsis; overflow: hidden; white-space: nowrap"
          >
            {{ item.client }}
          </div>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="warning" v-bind="attrs" v-on="on" v-show="item.message_failures">
                mdi-alert
              </v-icon>
            </template>
            <span
              >Error enviando a
              <span v-for="(errorCode, email) in item.message_failures" :key="email">
                {{ email.replace(',','.') }},&nbsp;
              </span>
            </span>
          </v-tooltip>
        </div>
      </template>
      <template v-slot:[`item.date`]="{ item }">
        {{ item.date | toString("Pp") }}
      </template>
      <template v-slot:[`item.paid`]="{ item }">
        <div class="d-flex flex-row align-center">
          <div style="width: 28px">
            <v-icon v-if="item.paid" color="success">mdi-check-circle</v-icon>
            <v-icon v-else-if="item.invoice.track" color="warning">mdi-clock</v-icon>
            <v-icon v-else color="warning">mdi-help-circle</v-icon>
          </div>
          {{ item.amount | toMoney }}
        </div>
      </template>
      <template v-slot:[`item.expenses_paid`]="{ item }">
        <div class="d-flex flex-row align-center">
          <div style="width: 28px">
            <v-icon v-if="item.expenses_paid" color="success"> mdi-check-circle </v-icon>
            <v-icon v-else-if="item.expenses_paid != null" color="warning">
              mdi-help-circle
            </v-icon>
          </div>
          {{ item.total_expenses | toMoney }}
        </div>
      </template>
      <template v-slot:[`item.group`]="{ item }">
        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-btn color="primary" icon dark v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-subheader class="primary--text">
              Honorarios
              <v-btn small icon :href="item.invoice.pdf_url" target="_blank"
                ><v-icon color="primary" small>mdi-open-in-new</v-icon>
              </v-btn>
            </v-subheader>
            <v-list-item v-if="!item.invoice.track" @click="togglePaid(item.id, !item.paid)">
              <v-list-item-icon>
                <v-icon>{{ item.paid ? "mdi-close-circle" : "mdi-check-circle" }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{
                  `Honorarios ${item.paid ? "no" : ""}  pagados`
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <template v-for="(expense, index) in item.expenses">
              <v-subheader class="primary--text" :key="`${expense.name}_header`"
                >Gasos {{ expense.total }}
                <v-btn small icon :href="expense.url" target="_blank">
                  <v-icon color="primary" small>mdi-open-in-new</v-icon>
                </v-btn>
              </v-subheader>
              <v-list-item
                @click="toggleExpenses(item.id, index, !expense.paid)"
                :key="`${expense.name}_toggle`"
              >
                <v-list-item-icon>
                  <v-icon>{{ expense.paid ? "mdi-close-circle" : "mdi-check-circle" }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{
                    `Gastos ${expense.paid ? "no" : ""} pagados`
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <v-list-item
              @click="
                cancelInvoiceId = item.id;
                cancelInvoiceDialog = true;
              "
            >
              <v-list-item-icon>
                <v-icon>mdi-delete</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ `Cancelar Factura` }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>
    <NewInvoiceDialog ref="invoiceDialog" />
    <ClientInvoices ref="clientInvoices" />
    <v-dialog v-model="cancelInvoiceDialog" width="500">
      <v-card v-if="invoices && cancelInvoiceId">
        <v-card-title class="text-h5 primary white--text"> Cancelar Factura </v-card-title>

        <v-card-text class="pt-4">
          Estas seguro que deseas cancelar la factura con folio
          {{ invoices[cancelInvoiceId].invoice.folio }}?
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="cancelInvoiceDialog = false"> No</v-btn>
          <v-btn color="primary" @click="cancelInvoice(cancelInvoiceId)"> Si, Borrar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import firestore from "@/firebase/firestore";
import NewInvoiceDialog from "./NewInvoiceDialog";
import ClientInvoices from "./ClientInvoices";

export default {
  components: {
    NewInvoiceDialog,
    ClientInvoices,
  },
  data() {
    return {
      dialogClient: null,
      clientDialog: false,
      search: "",
      sortBy: "date",
      sortDesc: true,
      cancelInvoiceId: null,
      cancelInvoiceDialog: false,
      headers: [
        { text: "No", value: "internal_id" },
        { text: "Cliente", value: "client" },
        { text: "Abogado", value: "abogado" },
        { text: "Fecha", value: "date" },
        { text: "Honorarios", value: "paid" },
        { text: "Gastos", value: "expenses_paid" },
        { text: "Acciones", value: "group" },
      ],
    };
  },
  methods: {
    viewClient(invoice) {
      this.$refs.clientInvoices.dialog = true;
      this.$refs.clientInvoices.clientId = this.invoices[invoice.id].customer_id;
    },
    newInvoice() {
      this.$refs.invoiceDialog.dialog = true;
    },
    togglePaid(invoiceId, newValue) {
      firestore.updateInvoice(invoiceId, { "invoice.paid": newValue });
    },
    cancelInvoice(invoiceId) {
      firestore.deleteInvoice(invoiceId);
      this.cancelInvoiceId = false;
      this.cancelInvoiceDialog = false;
    },
    toggleExpenses(invoiceId, index, newValue) {
      console.log("toggling expenses, ", invoiceId, index, newValue);
      if (Array.isArray(this.invoices[invoiceId].expenses)) {
        console.log("current expense is array, modifying and updating");
        const { expenses } = this.invoices[invoiceId];
        console.log(expenses);
        expenses[index].paid = newValue;
        console.log(expenses);
        firestore.updateInvoice(invoiceId, { expenses });
      } else {
        firestore.updateInvoice(invoiceId, { "expenses.paid": newValue });
      }
    },
  },
  computed: {
    ...mapState(["abogados", "clientes", "invoices"]),
    mappedInvoices() {
      if (!this.abogados || !this.clientes || !this.invoices) return [];

      return Object.values(this.invoices).map((invoice) => {
        const expenses =
          invoice.expenses == null || Array.isArray(invoice.expenses)
            ? invoice.expenses
            : [{ ...invoice.expenses, name: "Gastos Notaría" }];
        const totalExpenses =
          expenses != null ? expenses.reduce((p, c) => p + parseFloat(c.total), 0) : null;

        const expensesPaid = expenses != null ? expenses.reduce((p, c) => p && c.paid, true) : null;

        return {
          id: invoice.id,
          abogado: (this.abogados[invoice.abogado] || {}).name,
          client: (this.clientes[invoice.customer_id] || {}).name,
          date: invoice.invoice.date,
          internal_id: `${invoice.type[0]}.${invoice.internal_id}`,
          amount: invoice.invoice.total,
          total_expenses: totalExpenses,
          expenses_paid: expensesPaid,
          expenses,
          invoice: invoice.invoice,
          paid: invoice.invoice.paid,
          group: (this.clientes[invoice.customer_id] || {}).group,
          message_failures: invoice.message_failures,
        };
      });
    },
  },
};
</script>
